<template>
    <div class="login-facebook p-5 is-flex is-align-items-center is-justify-content-center">
        <div class="is-flex is-align-items-center is-justify-content-center is-flex-direction-column">
            <Spinner size="bg" />
            <div class="mt-5">
                {{ $t("social-media.collect-data", { name: "Facebook" }) }}
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from "vue";
import { useLogin } from "@kunsten/core";
import Spinner from "../../../shared/components/Spinner.vue";

export default defineComponent({
    name: "LoginFacebook",
    components: { Spinner },
    setup() {
        const { handleSocialMediaLogin } = useLogin();

        handleSocialMediaLogin("facebook", "/");

        return {};
    },
});
</script>

<style lang="scss" scoped>
@import "@/styles/colors.scss";

.login-facebook {
    height: 500px;
    color: $kun-white;
}
</style>
